<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('structures.structure')"
                icon="mdi-account-group"
            >
                <template v-slot:buttons>
                    <v-btn
                        v-if="isInRole(roles.structure.create)"
                        :loading="loading"
                        color="primary darken-1"
                        @click.native="dialog = true"
                    >
                        <v-icon>mdi-plus</v-icon>
                        {{ $t("add") }}
                    </v-btn>
                </template>
            </Page-Header>
            <template>
                <v-row class="pa-10" no-gutters justify="center">
                    <v-col
                        v-for="structure in structures"
                        :key="structure.structureGuid"
                        cols="auto"
                    >
                        <StructureCard
                            :deleteItem="deleteItem"
                            :editItem="editItem"
                            :structure="structure"
                        />
                    </v-col>
                </v-row>
                <!-- Delete popup -->
                <confirm-dialog
                    :openDialog="dialogDelete"
                    :onClicked="deleteItemConfirm"
                    :onClose="closeDelete"
                    toolBarColor="red darken-2"
                ></confirm-dialog>
                <!-- Save Dialog -->
                <Structure-Dialog
                    :dialog="dialog"
                    :close="close"
                    :editedIndex="editedIndex"
                    :editedItem="editedItem"
                    :refreshData="refreshData"
                    :employees="employees"
                    :key="dialog"
                />
            </template>
        </v-card>
    </v-container>
</template>
<script>
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import PageHeader from "../../components/PageHeader.vue";
import StructureDialog from "./StructureDialog.vue";
import StructureCard from "./StructureCard.vue";
import ManagementStructuresApi from "../../Api/ManagementStructuresApi";

export default {
    components: { PageHeader, ConfirmDialog, StructureDialog, StructureCard },
    data() {
        return {
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            employees: [],
            structures: [],
            editedItem: {},
            defaultItem: {},
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (!this.isInRole(this.roles.structure.window))
            this.redirectUnauthorizedUsers();

        this.loading = true;
        this.refreshData();
    },
    watch: {
        dialog(val) {
            val || this.close();
        },
        dialogDelete(val) {
            val || this.closeDelete();
        }
    },
    methods: {
        editItem(item) {
            this.editedIndex = this.structures.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialog = true;
        },
        deleteItem(item) {
            this.editedIndex = this.structures.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },
        deleteItemConfirm() {
            try {
                var deleteItem = this.structures[this.editedIndex];

                ManagementStructuresApi.Delete(deleteItem.structureGuid)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.closeDelete();
                        this.refreshData();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        close() {
            this.dialog = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
        },
        closeDelete() {
            this.dialogDelete = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1;
            });
            this.currFiles = [];
            this.files = [];
        },
        refreshData() {
            try {
                ManagementStructuresApi.Get()
                    .then(response => {
                        this.structures = response.data.data;
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
