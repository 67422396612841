import axios from "axios";

export default class ManagementStructuresApi {
    static Get() {
        return axios.get("ManagementStructures/Get").then(response => response);
    }

    static Create = structure => {
        return axios
            .post("ManagementStructures/Create", structure)
            .then(response => response);
    };

    static Update = structure => {
        return axios
            .put("ManagementStructures/Update", structure)
            .then(response => response);
    };

    static Delete = structureGuid => {
        return axios
            .delete(
                "ManagementStructures/Delete?StructureGuid=" + structureGuid
            )
            .then(response => response);
    };

    static async DeleteAttachment(fileName, baseGuid) {
        const res = await axios.post(`ManagementStructures/DeleteAttachment`, {
            fileName: fileName,
            baseGuid: baseGuid
        });

        return await res.data;
    }
}
