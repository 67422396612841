<template>
    <v-card
        class="ma-2"
        max-height="210"
        min-height="210"
        max-width="350"
        min-width="350"
        outlined
        shaped
    >
        <v-card
            :color="$vuetify.theme.dark ? '#2b2b2b' : '#EEEEEE'"
            max-height="150"
            min-height="150"
            elevation="0"
        >
            <v-list-item three-line>
                <v-list-item-avatar
                    style="border-radius: 10px !important;"
                    tile
                    width="85"
                    height="100%"
                    min-width="85"
                    color="grey"
                >
                    <v-img
                        class="rounded-sm"
                        v-if="
                            structure.employeePicture != null && !imageNotFound
                        "
                        @error="imageLoadError"
                        :src="structure.employeePicture"
                        :alt="structure.employeeName"
                    />
                    <v-icon x-large dark v-else>
                        mdi-account-circle
                    </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                    <div
                        :title="structure.departmentName"
                        class="mb-2"
                        style="color: #009688; font-size:15px"
                    >
                        {{ structure.position }}
                    </div>
                    <v-list-item-title class="mb-2 text-wrap">
                        {{ structure.name }}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{
                        structure.about
                    }}</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn
                v-if="isInRole(roles.structure.update)"
                color="primary darken-1"
                icon
                class="mx-2 my-1 white--text"
                @click="editItem(structure)"
            >
                <v-icon>mdi-pencil-outline</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                v-if="isInRole(roles.structure.delete)"
                icon
                color="red"
                class="mx-2 my-1"
                @click="deleteItem(structure)"
            >
                <v-icon>mdi-delete-outline</v-icon>
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: {
        deleteItem: {
            type: Function,
            required: true
        },
        editItem: {
            type: Function,
            required: true
        },
        structure: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            imageNotFound: false
        };
    },
    methods: {
        imageLoadError() {
            this.imageNotFound = true;
        }
    }
};
</script>
