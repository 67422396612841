<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <v-card-title class="pa-0">
                <v-toolbar color="primary" elevation="0" dark>{{
                    $t("structures.structure")
                }}</v-toolbar>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    outlined
                                    dense
                                    dir="auto"
                                    hide-details
                                    v-model="editedItem.name"
                                    :rules="rules"
                                    :label="$t('employees.employeeName')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    dir="auto"
                                    outlined
                                    dense
                                    hide-details
                                    v-model="editedItem.position"
                                    :rules="rules"
                                    :label="$t('positionTypes.position')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-text-field
                                    outlined
                                    dir="auto"
                                    dense
                                    hide-details
                                    v-model="editedItem.departmentName"
                                    :rules="rules"
                                    :label="$t('departments.departmentName')"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="12">
                                <v-textarea
                                    dir="auto"
                                    outlined
                                    hide-details
                                    :rows="4"
                                    :label="$t('structures.about')"
                                    v-model="editedItem.about"
                                ></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="12">
                                <v-file-input
                                    v-model="coverImage"
                                    small-chips
                                    :disabled="
                                        editedItem.files != undefined &&
                                            editedItem.files != null
                                    "
                                    show-size
                                    clearable
                                    prepend-icon=""
                                    append-icon="mdi-paperclip"
                                    :label="$t('employees.picture')"
                                    hide-details
                                    dense
                                    @click:clear="clearFiles"
                                    @change="inputChanged"
                                >
                                </v-file-input>
                            </v-col>
                            <v-col
                                v-if="
                                    editedItem.files != undefined &&
                                        editedItem.files != null
                                "
                                cols="12"
                                md="6"
                            >
                                <Custom-Image
                                    :image="editedItem.files"
                                    :maxWidth="250"
                                    :onDelete="deleteFile"
                                />
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="
                        isInRole(roles.structure.create) ||
                            isInRole(roles.structure.update)
                    "
                    color="primary darken-1 white--text"
                    @click="save"
                    :disabled="!valid"
                    :min-width="100"
                    :loading="loading"
                >
                    <v-icon>mdi-content-save-outline</v-icon>
                    {{ $t("save") }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="closeDialog">
                    {{ $t("cancel") }}
                </v-btn>
            </v-card-actions>
        </v-card>
        <confirm-dialog
            :openDialog="deleteFileDialog"
            :onClicked="deleteFileConfirm"
            :onClose="closeDeleteFile"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-dialog>
</template>
<script>
import ManagementStructuresApi from "../../Api/ManagementStructuresApi";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import CustomImage from "../../components/CustomImage.vue";

export default {
    components: { CustomImage, ConfirmDialog },
    props: {
        editedItem: {
            type: Object,
            required: true
        },
        editedIndex: {
            type: Number,
            required: true
        },
        dialog: {
            type: Boolean,
            required: true
        },
        close: {
            type: Function,
            required: true
        },
        refreshData: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            coverPreviewPath: null,
            coverImage: null,
            uploadedCoverImage: null,
            deletedFile: null,
            deleteFileDialog: false,
            valid: true,
            loading: false,
            rules: [value => !!value || this.$t("required")]
        };
    },
    watch: {
        dialog(val) {
            val || this.closeDialog();
        }
    },
    methods: {
        closeDialog() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.currFiles = [];
            this.files = [];
            this.close();
        },
        save() {
            try {
                if (!this.$refs.form.validate()) return;

                var structure = new FormData();

                structure.append("files", this.coverImage);

                for (var key in this.editedItem) {
                    if (this.editedItem[key])
                        structure.append(key, this.editedItem[key]);
                }

                if (this.editedItem.structureGuid == null)
                    this.create(structure);
                else this.update(structure);
            } catch (error) {
                console.log(error);
            }
        },
        create(dto) {
            try {
                this.loading = true;
                ManagementStructuresApi.Create(dto)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.refreshData();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        update(dto) {
            try {
                this.loading = true;
                ManagementStructuresApi.Update(dto)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.close();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                        this.refreshData();
                    });
            } catch (error) {
                console.log(error);
            }
        },
        inputChanged(e) {
            if (e == null || e == undefined) return;
            this.coverPreviewPath = URL.createObjectURL(e);
        },
        async deleteFileConfirm() {
            try {
                var res = await ManagementStructuresApi.DeleteAttachment(
                    this.deletedFile,
                    this.editedItem.structureGuid
                );

                if (res.status != this.responseStatus.ok) {
                    this.$toast.error(this.$t("error." + res.message));
                    return;
                }

                this.$toast.success(
                    this.$t("operationAccomplishedSuccessfully")
                );
                this.refreshData();
                this.deleteFileDialog = false;
                this.close();
            } catch (error) {
                console.log(error);
            }
        },
        deleteFile(file) {
            this.deletedFile = file;
            this.deleteFileDialog = true;
        },
        closeDeleteFile() {
            this.deleteFileDialog = false;
        },
        downloadFile(file) {
            window.open(file.path);
        },
        clearFiles() {
            this.coverImage = null;
            this.uploadedCoverImage = null;
            this.coverPreviewPath = null;
        }
    }
};
</script>
